@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@700&family=Open+Sans:wght@600&display=swap');

html,
body,
#root,
.ThemeWrapper {
  height: 100%;
}

.ThemeWrapper {
  position: fixed;
  width: 100%;
  overflow: scroll;
}

:root {
  --color-main: #ee7203;
  --font-accent: 'Catamaran', sans-serif;
}

#DarkTheme,
#LightTheme {
  background-color: var(--color-main-background);
}

#LightTheme {
  --color-main-background: #ffffff;
  --color-main-font: #fdfaf7;
  --color-grey-light: #f4f4f4;
  --color-grey-light-font: #8f8f98;
  --color-font-main: #1b1c26;
  --color-warning: #c95959;
  --color-input-background: #ffffff;
  --color-input-background-onwhite: #f4f4f4;
  --color-input-placeholder: #8f8f98;
  --color-input-font: #1b1c26;
  --color-choice-background: #f4f4f4;
  --color-choice-font: #8f8f98;
}

#DarkTheme {
  --color-main-background: #434953;
  --color-main-font: #ffffff;
  --color-grey-light: #565c65;
  --color-grey-light-font: #ffffff;
  --color-font-main: #fff;
  --color-warning: #c95959;
  --color-input-background: #eceff4;
  --color-input-background-onwhite: #eceff4;
  --color-input-placeholder: #767b84;
  --color-input-font: #1b1c26;
  --color-choice-background: #767b84;
  --color-choice-font: #ffffff;
}

/* THEMED SVG BACKGROUND IMAGES */

#LightTheme .close-icon {
  background-image: url('assets/close.svg');
}

#DarkTheme .close-icon {
  background-image: url('assets/close-dark.svg');
}

#LightTheme .back-icon {
  background-image: url('assets/back.svg');
}

#DarkTheme .back-icon {
  background-image: url('assets/back-dark.svg');
}

body {
  margin: 0;
  background: white !important;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background: grey;
}